import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { removeNumberPrefix } from '../../../../../utilities'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import { graphql } from 'gatsby'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Reveal from '../../../../../components/reveal'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter
    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba eshopu Elusia'}
          description={"Prípadová štúdia"}
          description2={'Profesionálny eshop na mieru s exkluzívnymi dizajnovými svietidlami.'}
          text={'Klient sa rozhodol pre redizajn eshopu, aby ho dostal na vyššiu úroveň a odlíšil sa od konkurencie. Po konzultáciách sme vytvorili moderný a prehľadný webdizajn, vďaka ktorému vyniknú jednotlivé produkty.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'UX & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' }
          ]}/>

        <AboutProject
          title="Úspešný eshop nevznikne sám od seba"
          text={'Tvorba eshopu premyslená do posledného detailu.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klient nás oslovil s projektom na kompletný redizajn <a class="blue-link" href="/eshop-na-mieru">eshopu</a>, ktorý by išiel s dobou čo sa týka najnovších technológií, možností administrácie a s dôrazom na čistý decentný web dizajn a UX prístup. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Kompletne „prekopať” starý eshop a vymyslieť jednoduché funkčné a elegantné <a class="blue-link" href="/ux-ui-dizajn">používateľské rozhranie</a>, kde bude zákazník schopný rýchlo sa orientovať v širokom sortimente svietidiel. Pri klientovi Elusia sme išli cestou „menej je viac”. Samozrejmosťou je najlepšia možná technologická úroveň <b>funkcií, rýchlosti a SEO.</b>',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Pri projektoch sa často navrhuje aj <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho <a class="blue-link" href="/webdizajn">webdizajnu</a>. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby.</b>',
            }
          ]}
        />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálny eshop sa bez neho nezaobíde.'}
          text={'Pokiaľ ide o dizajn stránky, eshop Elusia drží krok s najnovšími trendmi. Prepojenie estetiky a funkčnosti vedie k zvýšeniu dôveryhodnosti a rastu značky, keďže zákazníci sa do takého online obchodu vždy radi vrátia. Dôraz na vysoké konverzie a UX zas zabezpečuje vysoké obraty a zisky.'}
          projectDetail={'/nase-prace/webstranky/eshopy/elusia'}//TODO
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))}/>
         
        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="elusia" />
          </div>
        </Reveal>

        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          className="lightBlue"
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='blue-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný eshop a chce, aby mu prinášal čo najväčší zisk."
        />
        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.elusia.sk" target='_blank' className='link-secondary'>www.elusia.sk</a>
        </div>
        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Kuupto'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/eshopy/kuupto/'}
        />
      </Layout>
    )
  }
}


export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/elusia/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/elusia/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/elusia/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/elusia/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
